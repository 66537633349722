.card-title {
    font-size: 1.5rem; /* Zvětší titulek */
    margin-bottom: 15px; /* Přidá mezírku pod titulkem */
  }
  
  .card-text {
    font-size: 1.1rem; /* Zlepší čitelnost textu */
  }
  
  /* Přidáte-li více vlastních stylů, můžete je umístit sem */
  